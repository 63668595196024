@use "sass:color";

$mobile: "(max-width: 899px)";
$desktop: "(min-width: 900px)";
$assetPath: "/assets"; // which will use --base-href that replaces '~' in front

$cibc-fonts-stack: (
  Whitney: (light: (family: (Whitney-Light,
        Arial,
      ),
      weight: normal,
    ),
    light-italic: (family: (Whitney-LightItal,
        Arial,
      ),
      weight: normal,
      style: italic,
    ),
    regular: (family: (Whitney-Book,
        Arial,
      ),
      weight: normal,
    ),
    regular-italic: (family: (Whitney-BookItal,
        Arial,
      ),
      weight: normal,
      style: italic,
    ),
    medium: (family: (Whitney-Medium,
        Arial,
      ),
      weight: normal,
    ),
    medium-italic: (family: (Whitney-MediumItal,
        Arial,
      ),
      weight: normal,
      style: italic,
    ),
    sb: (family: (Whitney-Semibld,
        Arial,
      ),
      weight: normal,
    ),
    sb-italic: (family: (Whitney-SemibldItal,
        Arial,
      ),
      weight: bold,
      style: italic,
    ),
    bold: (family: (Whitney-Bold,
        Arial,
      ),
      weight: bold,
    ),
    bold-italic: (family: (Whitney-BoldItal,
        Arial,
      ),
      weight: bold,
      style: italic,
    ),
  ),
  Arial: (regular: (family: (Arial,
      ),
      weight: normal,
    ),
    regular-italic: (family: (Arial,
      ),
      weight: normal,
      style: italic,
    ),
    bold: (family: (Arial,
      ),
      weight: bold,
    ),
    bold-italic: (family: (Arial,
      ),
      weight: bold,
      style: italic,
    ),
  ),
);

$cibc-theme-colors: (
  "color01": #ffffff,
  "color02": #383b3e,
  "color03": #606366,
  "color04": #86888a,
  "color05": #bbbcbc,
  "color06": #dddddd,
  "color07": #f2f3f2,
  "color08": #d9d9d9,
  "color10": #c41f3e,
  "color11": #8b1d41,
  "color12": #bbe3e2,
  "color13": #d3edee,
  "color14": #ccddf2,
  "color15": #dfe9f6,
  "color16": #f2dde0,
  "color17": #f5e3e8,
  "color20": #4a9e6b,
  "color21": #e4efea,
  "color22": #d65421,
  "color23": #fcf1d7,
  "color24": #11608a,
  "color30": #0d8008,
  "color31": #d41a1a,
  "color32": #2779b0,
  "color33": #333479,
  "color34": #99304f,
  "color35": #c35414,
  "color36": #006254,
  "color37": #7b6e74,
  "color38": #f2f6fc,
  "color39": #5C99D6,
  "color40": #3885BA,
  "color41": #FFECF1,
  "color42": #404347,
  "color43": #EBF2FA,
  "color50": #0767a8,
  "color51": #333,
  "color52": #666,
  "color53": #ddd,
  "color54": #c3c3c3,
  "color55": #005377,
  "color56": #8a1d41,
  "color57": #994E04,
  "color58": #383B3D,
  "color59": #DEE9F7,
  "color60": #007aae,
  "color61": #ebf4e9,
  "color62": #EEE,
  "color63": #999,
  "color64": #F27878,
  "color65": #80CDBE,
  "color66": #1F8561,
  "color67": #CC4B18,
  "color68": #F8FCFF,
  "color69": #E9F6F6,
  "color70": #000,
  "color71": #095933,
  "color72": #979797,
  "color73": #0A6D42,
  "color74": #B73A18,
  "color75": #932516,
  "color76": #333333,
  "color77": #C17200,
  "color78": #383B3E,
  "color79": #397952,
  "color80": #d3d3d3,
  "color90": #731836,
  "color91": #EAF5F0,
  "color92": #FAF1F3,
  "color93": #fff4da,
  "color94": #E7F2ED,
  "color95": #BCE4E3,
  "color96": #0a6d421a,
  "color97": #EBF7F7,
  "color98": #c41f3e1a,
  "color99": #0A6D42,
  "color100": #FCEAE3,
  "color101": #FBEAEF,
  "color102": #F2D4DA,
  "color103": #0A6D42,
  "color104": #fafafa,
  "color105": #E6F3F3
);

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function color($key) {
  $key: "color" + $key;

  @if map-has-key($cibc-theme-colors, $key) {
    @return map-get($cibc-theme-colors, $key);
  }

  @warn "Unknown `#{$key}` in $cibc-theme-colors.";
  @return null;
}

@function colorTrans($key, $trans) {
  $key: "color" + $key;

  @if map-has-key($cibc-theme-colors, $key) {
    @return color.change(map-get($cibc-theme-colors, $key), $alpha: $trans);
  }

  @warn "Unknown `#{$key}` in $cibc-theme-colors.";
  @return null;
}

@mixin fontMixin($size, $group: Arial, $variant: regular, $underline: null, $font-stack: $cibc-fonts-stack) {
  $properties: family weight style;

  font-size: $size + px;
  line-height: 1.4;

  $font-properties: map-deep-get($font-stack, $group, $variant);

  @if $font-properties {

    @each $property,
    $values in $font-properties {
      @if contains($properties, $property) {
        font-#{$property}: map-get($font-properties, $property);
      }
    }
  }

  @if ($underline) {
    text-decoration: underline;
  }
}

@mixin col-width($width) {
  display: inline-block;
  width: $width;
  box-sizing: border-box;
  // max-width: $width;
}

@media (prefers-color-scheme: dark) {
  // window.matchMedia('(prefers-color-scheme: dark)').matches
  // window.matchMedia('(prefers-color-scheme: dark)').matches
  // https://medium.com/@svenbudak/how-to-implement-dark-light-mode-in-angular-mateiral-with-prefers-color-scheme-ce3e980e2ea5

  body {
    // background-color: black;
    // color: white;
  }
}

@media (prefers-color-scheme: light) {
  body {
    // background-color: white;
    // color: black;
  }
}